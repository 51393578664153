export const environment = {
  production: true,
  url: 'https://apiliterama.tindin.com.br',
  REDIRECT_URI: 'https://literama.souionica.com.br/redirectlogin',
  OIDC_AUTHORIZE_URL: 'https://ftd-apim-prd.azure-api.net/lti-api/v1/auth/adb2c/authorize',
  CLIENT_ID: 'a0ea15b6-e02b-4af2-949f-9964ade1e9eb',
  SOCKET_URL: 'wss://pptcm3hjmf.execute-api.us-east-1.amazonaws.com/production',
  LOGOUT: 'https://app.souionica.com.br?auth=true'
}

export const typing = {
  typingEffect: false,
  interval: 50
}

export const colideAgain = {
  time: 0.05
}

export const intervalPosition = {
  interval: 10000
}
